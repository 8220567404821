<app-alert></app-alert>

<div class="connectPage">
    <form action="" #connectForm="ngForm" class="form"
        (ngSubmit)="handleCredentialsSubmitting(connectForm)">
        <h1 class="mat-h1 connectForm__fullLine">
            {{ connectionID ? 'Edit credentials' : 'Connect a database' }}
        </h1>

        <mat-form-field appearance="outline" class="connectForm__title">
            <mat-label>Connection title</mat-label>
            <input matInput name="title" #title="ngModel"
                data-testid="connection-title-input"
                angulartics2On="change"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: title is edited"
                [required]="db.connectionType === 'agent'"
                [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
                [disabled]="submitting"
                [(ngModel)]="db.title">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Database type</mat-label>
            <mat-select name="dbtype" #dbtype="ngModel"
                data-testid="connection-dbtype-select"
                angulartics2On="click"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: db type is selected"
                [angularticsProperties]="{'dbType': db.type}"
                [disabled]="submitting"
                [(ngModel)]="db.type"
                (ngModelChange)="dbTypeChange()" >
                <mat-option value="mysql">MySQL</mat-option>
                <mat-option value="postgres">PostgreSQL</mat-option>
                <mat-option value="mongodb">MongoDB</mat-option>
                <mat-option value="dynamodb" *ngIf="db.connectionType === 'direct'">DynamoDB</mat-option>
                <mat-option value="oracledb">Oracle</mat-option>
                <mat-option value="mssql">MS SQL</mat-option>
                <mat-option value="ibmdb2">IBM DB2</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="connectForm__fullLine">
            <mat-button-toggle-group name="connectionType"
                data-testid="connection-type-toggle"
                class="connectForm__typeSwitch"
                [disabled]="submitting || db.isTestConnection"
                [(ngModel)]="db.connectionType">
                <mat-button-toggle value="direct">Direct connection</mat-button-toggle>
                <mat-button-toggle value="agent">Agent connection</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div *ngIf="db && db.connectionType === 'direct' && !db.isTestConnection && isSaas && db.type !== 'dynamodb'"
            data-testid="direct-connection-warning"
            class="connectForm__fullLine">
            <div *ngIf="connectionID; else warningAlert" class="warningMessage">
                <mat-icon class="warningMessage__icon">warning_amber</mat-icon>
                <div class="mat-body-1">
                    Please make sure our servers are able to access your database. <br/> Rocketadmin uses
                    <app-ip-address-button ip="18.221.81.73"></app-ip-address-button>
                    IP address for all outbound requests.
                </div>
            </div>

            <ng-template #warningAlert>
                <app-alert [alert]="warning">
                    <div class="mat-body-1" style="margin-bottom: 0">
                        Please make sure our servers are able to access your database. <br/> Rocketadmin uses
                        <app-ip-address-button ip="18.221.81.73"></app-ip-address-button>
                        IP address for all outbound requests.
                    </div>
                </app-alert>
            </ng-template>
        </div>

        <app-mysql-credentials-form *ngIf="db.type === 'mysql' && db.connectionType === 'direct'"
            [ngClass]="{
                'credentials-fieldset': !db.isTestConnection,
                'credentials-fieldset-no-warning': db.isTestConnection || !isSaas
            }"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent()"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-mysql-credentials-form>

        <app-postgres-credentials-form *ngIf="db.type === 'postgres' && db.connectionType === 'direct'"
            [ngClass]="{
                'credentials-fieldset': !db.isTestConnection,
                'credentials-fieldset-no-warning': db.isTestConnection || !isSaas
            }"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-postgres-credentials-form>

        <app-mongodb-credentials-form *ngIf="db.type === 'mongodb' && db.connectionType === 'direct'"
            [ngClass]="{
                'credentials-fieldset': !db.isTestConnection,
                'credentials-fieldset-no-warning': db.isTestConnection || !isSaas
            }"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-mongodb-credentials-form>

        <app-dynamodb-credentials-form *ngIf="db.type === 'dynamodb' && db.connectionType === 'direct'"
            class="credentials-fieldset-no-warning"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-dynamodb-credentials-form>

        <app-oracledb-credentials-form *ngIf="db.type === 'oracledb' && db.connectionType === 'direct'"
            [ngClass]="{
                'credentials-fieldset': !db.isTestConnection,
                'credentials-fieldset-no-warning': db.isTestConnection || !isSaas
            }"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-oracledb-credentials-form>

        <app-mssql-credentials-form *ngIf="db.type === 'mssql' && db.connectionType === 'direct'"
            [ngClass]="{
                'credentials-fieldset': !db.isTestConnection,
                'credentials-fieldset-no-warning': db.isTestConnection || !isSaas
            }"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-mssql-credentials-form>

        <app-db2-credentials-form *ngIf="db.type === 'ibmdb2' && db.connectionType === 'direct'"
            [ngClass]="{
                'credentials-fieldset': !db.isTestConnection,
                'credentials-fieldset-no-warning': db.isTestConnection || !isSaas
            }"
            [connection]="db"
            [submitting]="submitting"
            [accessLevel]="accessLevel"
            [masterKey]="masterKey"
            [readonly]="(accessLevel === 'readonly' || db.isTestConnection) && db.id"
            (switchToAgent)="switchToAgent"
            (masterKeyChange)="handleMasterKeyChange($event)">
        </app-db2-credentials-form>

        <div *ngIf="db.connectionType === 'agent'" class="connectForm__fullLine instruction">

            <div class="instruction__docker">
                <p class="mat-body-1">
                    Visit our DockerHub page to install the Rocketadmin agent via Docker.
                </p>

                <a mat-flat-button color="accent" class="github-button"
                    href="https://hub.docker.com/r/rocketadmin/rocketadmin-agent" target="_blank"
                    data-testid="connection-agent-dockerhub-link"
                    angulartics2On="click"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: open github agent page">
                    <svg class="github-icon" xmlns="http://www.w3.org/2000/svg"
                        aria-label="Docker" role="img"
                        viewBox="0 0 512 512"><rect
                        width="512" height="512"
                        rx="15%"
                        fill="transparent"/><path stroke="#fff" stroke-width="38" d="M296 226h42m-92 0h42m-91 0h42m-91 0h41m-91 0h42m8-46h41m8 0h42m7 0h42m-42-46h42"/><path fill="#fff" d="m472 228s-18-17-55-11c-4-29-35-46-35-46s-29 35-8 74c-6 3-16 7-31 7H68c-5 19-5 145 133 145 99 0 173-46 208-130 52 4 63-39 63-39"/></svg>

                    <span>Install Rocketadmin agent</span>
                </a>

                <p class="mat-body-1">
                    Once the installation is successfully completed, the new connection will appear in the list of your admin panels.
                </p>

                <a mat-stroked-button color="accent"
                    href="https://docs.rocketadmin.com/Create%20connections/Agent%20connection/agent_connection"
                    target="_blank"
                    data-testid="connection-agent-instruction-link"
                    angulartics2On="click"
                    angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: open agent connection instruction">
                    Check out the complete guide
                </a>
            </div>

            <div *ngIf="connectionToken" class="agent-token">
                <mat-form-field appearance="outline" style="width:100%">
                    <mat-label>Token</mat-label>
                    <input matInput name="token" #token="ngModel" readonly
                        data-testid="connection-agent-token-input"
                        [(ngModel)]="connectionToken">
                    <mat-hint>
                        Please save this token and use it to configure your agent connection in .config.env file.
                    </mat-hint>
                </mat-form-field>
                <button type="button" mat-icon-button
                    data-testid="connection-agent-token-copy-button"
                    class="agent-token__copy-button"
                    matTooltip="Copy mater password"
                    [cdkCopyToClipboard]="connectionToken"
                    (cdkCopyToClipboardCopied)="showCopyNotification('Connection token was copied to clipboard.')">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>
        </div>

        <!-- test connection actions -->
        <div *ngIf="db.isTestConnection" class="test-connection-actions">
            <p class="mat-body-1 test-connection-message">You cannot edit test connection.</p>
            <a *ngIf="db.isTestConnection"
                routerLink="/connect-db"
                data-testid="test-connection-actions-create-own-link"
                mat-flat-button color="accent">
                Create your own
            </a>
            <button type="button" mat-button color="warn"
                data-testid="test-connection-actions-delete-button"
                angulartics2On="click"
                angularticsAction="Connection creds test: delete db is clicked"
                [disabled]="submitting"
                (click)="confirmDeleteConnection(db, $event)">
                Delete
            </button>
        </div>

        <!-- add connection actions -->
        <div class="actions" *ngIf="!db.id && !db.isTestConnection">
            <a mat-stroked-button routerLink="/connections-list"
                data-testid="add-connection-actions-back-link"
                class="delete-button">
                Back
            </a>
            <button *ngIf="db.connectionType === 'direct'" type="button" mat-button color="primary" class="test-button"
                data-testid="add-connection-actions-test-button"
                angulartics2On="click"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: test db creds is clicked"
                (click)="testConnection()"
                [disabled]="submitting">
                Test connection
            </button>
            <button mat-flat-button type="submit" *ngIf="!connectionToken && !connectionID"
                data-testid="add-connection-actions-add-button"
                color="primary"
                angulartics2On="click"
                angularticsAction="Connection creds add: db add clicked"
                [disabled]="submitting">
                {{ submitting ? 'Checking' : 'Add'}}
            </button>
            <a *ngIf="connectionToken && connectionID" mat-flat-button color="primary"
                routerLink="/dashboard/{{connectionID}}"
                data-testid="add-connection-actions-open-dashboard-link">
                Open dashboard
            </a>
        </div>

        <!-- edit connection actions -->
        <div class="actions" *ngIf="accessLevel && db.id && accessLevel === 'edit' && !db.isTestConnection">
            <button type="button" mat-button color="warn"
                class="delete-button"
                data-testid="edit-connection-actions-delete-button"
                angulartics2On="click"
                angularticsAction="Connection creds edit: delete db is clicked"
                [disabled]="submitting"
                (click)="confirmDeleteConnection(db, $event)">
                Delete
            </button>
            <button type="button" mat-button color="primary" class="test-button"
                data-testid="edit-connection-actions-test-button"
                angulartics2On="click"
                angularticsAction="Connection creds {{ db.id ? 'edit' : 'add' }}: test db creads is clicked"
                (click)="testConnection()"
                [disabled]="submitting">
                Test connection
            </button>

            <!-- right button -->
            <button type="submit" *ngIf="db.id"
                mat-flat-button color="primary"
                data-testid="edit-connection-actions-edit-button"
                angulartics2On="click"
                angularticsAction="Connection creds edit: db edit is clicked"
                [disabled]="submitting || db.isTestConnection">
                {{ submitting ? 'Checking' : 'Edit'}}
            </button>

            <a *ngIf="connectionToken && connectionID" mat-flat-button color="primary"
                routerLink="/dashboard/{{connectionID}}"
                data-testid="edit-connection-actions-open-dashboard-link">
                Open dashboard
            </a>
        </div>
    </form>
</div>
